import DefaultIconSet from "@/consts/DefaultIconSet";
import DefaultResumePDFUrl from "@/consts/DefaultResumePDFUrl";
import { ToolbarConfigType } from "@/types/base";

const DefaultToolbarProps: ToolbarConfigType = {
  defaultJsonVisible: false,
  iconSet: DefaultIconSet,
  hide: true,
  hideDarkModeSelect: true,
  hideDemoOptions: true,
  hideFullResumeOption: false,
  hideJson: true,
  hidePDF: false,
  hidePrint: false,
  hideResumeOption: false,
  hideResumeSelect: true,
  resumePDFUrl: DefaultResumePDFUrl,
};

export default DefaultToolbarProps;
