import ResumeConfigContext from "@/context/resume/ResumeConfigContext";
import { useContext } from "react";

const useResumeConfig = () => {
  const resumeConfigContext = useContext(ResumeConfigContext);

  if (!resumeConfigContext) {
    throw new Error("resumeConfigContext has to be used within <ResumeConfigContext.Provider>");
  }

  return resumeConfigContext;
};

export default useResumeConfig;
