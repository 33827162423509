import { checkKeyViewable, isArray, isNil } from "@/utils";

import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import { IdealCompanyType } from "@/types/resume";
import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type IdealCompanyProps = {
  idealCompany?: IdealCompanyType;
  header?: string;
};

const IdealCompany = ({ idealCompany, header = "Ideal Company:" }: IdealCompanyProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("extras.idealCompany", viewable) ||
    isNil(idealCompany) ||
    !isArray(idealCompany) ||
    (isArray(idealCompany) && !(idealCompany.length > 0))
  ) {
    return null;
  }
  const heading = titles?.idealCompany ?? header;

  return (
    <Section className="cp-resume-ideal-company">
      <h2 className="cp-resume-ideal-company-title">{heading}</h2>

      <HighlightsList highlights={idealCompany} />
    </Section>
  );
};

export default IdealCompany;
