import { IconSetType } from "@/types/resume";

type ToolbarJsonToggleOptionBtnProps = {
  jsonVisible?: boolean;
  onClick?: () => void;
  iconSet: IconSetType;
};

const ToolbarJsonToggleOptionBtn = ({ jsonVisible = false, onClick, iconSet }: ToolbarJsonToggleOptionBtnProps) => {
  const toggleMessaging = `${jsonVisible ? "Click to hide Resume JSON" : "Click to view Resume JSON"}`;

  return (
    <button
      className="cp-resume-toolbar-option cp-resume-toolbar-json-toggle"
      title={toggleMessaging}
      onClick={onClick}
    >
      <i className={`${jsonVisible ? iconSet.anglesDown : iconSet.anglesUp}`} />
      <span className="sr-only">{toggleMessaging}</span>
    </button>
  );
};

export default ToolbarJsonToggleOptionBtn;
