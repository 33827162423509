import { ComponentProps, JSX } from "react";
import { DateMonthStringsSet } from "@/consts";
import { DateTextType } from "@/types/resume";
import DateTime from "@/components/resume/dates/DateTime";
import classNames from "classnames";

type StartDateProps = ComponentProps<"time"> &
  DateTextType & {
    noStart?: string;
  };

const StartDate = ({
  date,
  noStart = "Not Started",
  months = DateMonthStringsSet,
  className,
}: StartDateProps): JSX.Element | null => {
  return <DateTime className={classNames("cp-start-date", className)} date={date} noDate={noStart} months={months} />;
};

export default StartDate;
