import { ResumeSupportedThemeType } from "@/types/resume";
//FIXME: is there to create an array of a certain type with the known values?
const DefaultResumeThemes: ResumeSupportedThemeType[] = [
  "base",
  "tall",
  "wide",
  "side-scroll",
  "wide-1-col",
  "wide-2-col",
  "wide-1-2-col",
  "wide-1-2-3-col",
  "wide-flip",
  "super-wide",
  "full-wide",
  "supports-dark",
  "force-dark",
];

export default DefaultResumeThemes;
