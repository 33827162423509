import "@/components/resume/lists/highlights/HighlightsList.scss";

import HighlightItem from "@/components/resume/lists/highlights/HighlightItem";
import { HighlightsType } from "@/types/resume";
import { JSX } from "react";
import { isArray } from "@/utils";

type WorkHighlightsListProps = {
  highlights?: HighlightsType;
};

const HighlightsList = ({ highlights }: WorkHighlightsListProps): JSX.Element | null => {
  if (!highlights || !isArray(highlights) || highlights.length < 1) {
    return null;
  }

  return (
    <ul className="cp-resume-highlights-list" title="Highlights">
      {highlights.map((highlight, index) => {
        return <HighlightItem key={index} highlight={highlight} />;
      })}
    </ul>
  );
};

export default HighlightsList;
