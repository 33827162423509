import "@/components/resume/lists/skills/Skills.scss";
import { checkKeyViewable, createKeyFrom, isArray } from "@/utils";

import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import SkillItem from "@/components/resume/lists/skills/SkillItem";
import { SkillsType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type SkillsProps = {
  skills?: SkillsType;
};

const Skills = ({ skills = [] }: SkillsProps): JSX.Element | null => {
  const { viewable = [] } = useResumeConfig();

  if (!checkKeyViewable("skills", viewable) || !skills || !isArray(skills) || skills.length < 1) {
    return null;
  }

  return (
    <Section className="cp-resume-skills" heading="Skills:">
      <ul className="cp-resume-skills-list">
        {skills.map((skill) => {
          return skill.hide ? null : <SkillItem key={createKeyFrom(skill.name)} skill={skill} />;
        })}
      </ul>
    </Section>
  );
};

export default Skills;
