import { FetchResumeErrorType, ResumeType } from "@/types/resume";

const fetchResume = async (
  url: string,
  onSuccess: (resData: ResumeType) => void,
  onFail?: (error: FetchResumeErrorType) => void
) => {
  const fetchUrl = `${url}?_${Date.now()}`;
  const errorMsg = `Fetch for resume @ '${url}'`;

  try {
    const response = await fetch(fetchUrl, {
      method: "GET",
    });
    const data = await response.json();
    if (response.ok) onSuccess(data);
    if (!response.ok && onFail) onFail({ message: errorMsg, response });
  } catch (error) {
    console.error(`${errorMsg} Error:`, error);
    if (onFail) onFail({ message: errorMsg, error });
  }
};

export default fetchResume;
