import "@/components/resume/detail/Detail.scss";

import {
  AwardsType,
  CertificatesType,
  EducationType,
  InterestsType,
  LanguagesType,
  ProjectsType,
  PublicationsType,
  ReferencesType,
  ResumeExtrasType,
  SkillsType,
  VolunteerType,
  WorkType,
} from "@/types/resume";
import { JSX, useRef } from "react";

import Awards from "@/components/resume/lists/awards/Awards";
import Certificates from "@/components/resume/lists/certificates/Certificates";
import Education from "@/components/resume/lists/education/Education";
import IdealCompany from "@/components/resume/sections/IdealCompany";
import Interests from "@/components/resume/lists/interests/Interests";
import Languages from "@/components/resume/lists/languages/Languages";
import Projects from "@/components/resume/lists/projects/Projects";
import Publications from "@/components/resume/lists/publications/Publications";
import References from "@/components/resume/lists/references/References";
import Skills from "@/components/resume/lists/skills/Skills";
import Summary from "@/components/resume/sections/Summary";
import Volunteer from "@/components/resume/lists/volunteer/Volunteer";
import Work from "@/components/resume/lists/work/Work";
import { useResumeConfig } from "@/context/resume";
import { useSideWheelScroll } from "@/utils";

type DetailProps = {
  skills?: SkillsType;
  summaryText?: string;
  work?: WorkType;
  volunteer?: VolunteerType;
  education?: EducationType;
  awards?: AwardsType;
  certificates?: CertificatesType;
  publications?: PublicationsType;
  languages?: LanguagesType;
  interests?: InterestsType;
  references?: ReferencesType;
  projects?: ProjectsType;
  extras?: ResumeExtrasType;
};

const Detail = ({
  summaryText,
  skills,
  work,
  volunteer,
  education,
  awards,
  certificates,
  publications,
  languages,
  interests,
  references,
  projects,
  extras,
}: DetailProps): JSX.Element => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { viewable = [], theme } = useResumeConfig();

  useSideWheelScroll({
    scrollEl: scrollRef.current,
    theme,
  });

  return (
    <main className="cp-resume-detail" ref={scrollRef}>
      {viewable.map((view) => {
        let toBuild;
        const viewKey = `cp-resume-detail-${view}`;

        switch (view) {
          case "basics.summary":
            toBuild = summaryText ? <Summary summaryText={summaryText} key={viewKey} /> : null;
            break;
          case "skills":
            toBuild = skills ? <Skills skills={skills} key={viewKey} /> : null;
            break;
          case "work":
            toBuild = work ? <Work work={work} key={viewKey} /> : null;
            break;
          case "volunteer":
            toBuild = volunteer ? <Volunteer volunteer={volunteer} key={viewKey} /> : null;
            break;
          case "education":
            toBuild = education ? <Education education={education} key={viewKey} /> : null;
            break;
          case "awards":
            toBuild = awards ? <Awards awards={awards} key={viewKey} /> : null;
            break;
          case "certificates":
            toBuild = certificates ? <Certificates certificates={certificates} key={viewKey} /> : null;
            break;
          case "publications":
            toBuild = publications ? <Publications publications={publications} key={viewKey} /> : null;
            break;
          case "languages":
            toBuild = languages ? <Languages languages={languages} key={viewKey} /> : null;
            break;
          case "interests":
            toBuild = interests ? <Interests interests={interests} key={viewKey} /> : null;
            break;
          case "references":
            toBuild = interests ? <References references={references} key={viewKey} /> : null;
            break;
          case "projects":
            toBuild = projects ? <Projects projects={projects} key={viewKey} /> : null;
            break;
          case "extras.idealCompany":
            toBuild = extras?.idealCompany ? <IdealCompany idealCompany={extras?.idealCompany} key={viewKey} /> : null;
            break;
          default:
            toBuild = null;
        }

        return toBuild;
      })}
    </main>
  );
};

export default Detail;
