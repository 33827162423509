import { useEffect, useState } from "react";
import { ResumeType } from "@/types/resume";
import fetchResume from "@/utils/fetchResume";

type UseFetchResumeProps = {
  resumeUrl: string;
  isGated?: boolean;
};

type UseFetchResumeReturnProps = {
  resumeUrl: string;
  resumeData?: ResumeType;
  isFetching: boolean;
  isFetched: boolean;
  isComplete: boolean;
  isFailed: boolean;
};

const useFetchResume = ({ resumeUrl, isGated = false }: UseFetchResumeProps): UseFetchResumeReturnProps => {
  const [resumeData, setResumeData] = useState<ResumeType>();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (!isGated && !!resumeUrl && !isFetching && !resumeData && !isFetched) {
      setIsFetching(true);
      fetchResume(
        resumeUrl,
        (data) => {
          setResumeData(data);
          setIsFetching(false);
          setIsFetched(true);
        },
        () => {
          setResumeData(undefined);
          setIsFetching(false);
          setIsFailed(true);
          setIsFetched(true);
        }
      ).finally(() => {
        setTimeout(() => setIsComplete(true), 1); //NOTE: This timeout helps with animations
      });
    }
  }, [isFetching, isFetched, resumeUrl, resumeData, isGated]);

  return {
    isComplete,
    isFailed,
    isFetching,
    isFetched,
    resumeUrl,
    resumeData,
  };
};

export default useFetchResume;
