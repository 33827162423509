import { ResumeSupportedThemeType, ResumeThemeType } from "@/types/resume";
import { DefaultResumeThemes } from "@/consts";
import isString from "@/utils/base/isString";

const isSupportedTheme = (theme: ResumeThemeType): boolean => {
  if (isString(theme)) {
    return DefaultResumeThemes.includes(<ResumeSupportedThemeType>theme);
  }
  return false;
};

export default isSupportedTheme;
