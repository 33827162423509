import "@/components/toolbar/ToolbarBuiltWith.scss";

import { ComponentProps } from "react";
import ToolbarBuildWithLink from "@/components/toolbar/ToolbarBuiltWithLink";
import classNames from "classnames";
import reactLogo from "@/images/icon-reactjs.svg";
import typescriptLogo from "@/images/icon-typescript.svg";
import viteLogo from "@/images/icon-vite.svg";

const builtWithLinks = [
  {
    builtWith: "react",
    logoSrc: reactLogo,
    logoAlt: "ReactJS Logo",
    linkText: "Built with ReactJS",
    title: "ReactJS",
    href: "https://react.dev/",
  },
  {
    builtWith: "typescript",
    logoSrc: typescriptLogo,
    logoAlt: "TypeScript Logo",
    linkText: "Built with TypeScript",
    title: "TypeScript",
    href: "https://www.typescriptlang.org/",
  },
  {
    builtWith: "vite",
    logoSrc: viteLogo,
    logoAlt: "Vite Logo",
    linkText: "Built with Vite",
    title: "Vite",
    href: "https://vitejs.dev/",
  },
];

export type ToolbarBuildWithProps = ComponentProps<"div">;

const ToolbarBuiltWith = ({ className, ...restProps }: ToolbarBuildWithProps) => {
  return (
    <div {...restProps} className={classNames("cp-resume-toolbar-built-with", className)}>
      <span>Built with: </span>

      {builtWithLinks.map((builtWithLink, bwi) => {
        const { builtWith, ...restBuildWithLinkProps } = builtWithLink;
        return <ToolbarBuildWithLink key={`built-with-${builtWith}-${bwi}`} {...restBuildWithLinkProps} />;
      })}
    </div>
  );
};

export default ToolbarBuiltWith;
