/**
 *
 * @param el {HTMLElement}
 */
const clipElement = (el: HTMLElement) => {
  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
  }

  navigator.clipboard.writeText(el.innerText).catch((reason) => {
    console.log("Sorry! Unable to clipboard", reason);
  });
};

export default clipElement;
