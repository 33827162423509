import "@/components/resume/lists/highlights/HighlightItem.scss";
import { isEmpty, isNil } from "@/utils";

import { HighlightType } from "@/types/resume";
import { JSX } from "react";

type WorkHighlightItemProps = {
  highlight?: HighlightType;
};

const HighlightItem = ({ highlight }: WorkHighlightItemProps): JSX.Element | null => {
  if (isNil(highlight) || isEmpty(highlight)) {
    return null;
  }

  return <li className="cp-resume-highlight-item">{highlight}</li>;
};

export default HighlightItem;
