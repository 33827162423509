import "@/components/ResumeApp.scss";

import { DefaultIconSet, DefaultResumePDFUrl, DefaultResumeUrl, DefaultSelectResumeOption, EnvConfig } from "@/consts";
import { JSX, useState } from "react";
import { ResumeConfigType, ResumeUrl } from "@/types/resume";
import { ResumeSelectOption, ToolbarConfigType } from "@/types/base";
import {
  getResumeConfigFromDefault,
  getResumeUrlFromHref,
  useDemoConfig,
  useFetchResume,
  useTheme,
  useToolbarConfig,
} from "@/utils";

import CPResume from "@/components/resume/CPResume";
import CPResumeComponent from "@/components/resume/CPResumeComponent";
import ResumeThemeSelect from "@/components/form/ResumeThemeSelect";
import Toolbar from "@/components/toolbar/Toolbar";
import classNames from "classnames";
import resumeConfigJson from "@/data/resumeConfig.json";
import resumeFullConfigJson from "@/data/resumeFullConfig.json";

const ResumeConfigDefaults = getResumeConfigFromDefault(resumeConfigJson as ResumeConfigType);
const ResumeFullConfigDefaults = getResumeConfigFromDefault(resumeFullConfigJson as ResumeConfigType);

type ResumeAppProps = {
  resumeConfig?: ResumeConfigType;
  resumeFullConfig?: ResumeConfigType;
  defaultResumeSelectOption?: ResumeSelectOption;
  toolbarConfig?: Omit<ToolbarConfigType, "iconSet" | "resumeData">;
  demo?: boolean;
};

const ResumeApp = ({
  resumeConfig = ResumeConfigDefaults,
  resumeFullConfig = ResumeFullConfigDefaults,
  defaultResumeSelectOption = DefaultSelectResumeOption,
  toolbarConfig,
  demo = EnvConfig.demoMode,
}: ResumeAppProps): JSX.Element => {
  const [selectedResume, setSelectedResume] = useState<ResumeSelectOption>(defaultResumeSelectOption);

  const { demoConfig, isDemo, resumeDemoData } = useDemoConfig({ demo, selectedResume });

  const currentResumeConfig =
    isDemo && demoConfig ? demoConfig : selectedResume === "res-full-config" ? resumeFullConfig : resumeConfig;

  const resumeUrl: ResumeUrl = currentResumeConfig?.resumeUrl ?? DefaultResumeUrl;
  const resumeUrlToLoad = getResumeUrlFromHref({ resumeUrl });
  const resumePDFUrl: ResumeUrl = currentResumeConfig?.resumePDFUrl ?? DefaultResumePDFUrl;

  //FIXME: Fetch resume configs? before fetching resumeData
  const { resumeData, isFetching, isComplete, isFailed } = useFetchResume({
    resumeUrl: resumeUrlToLoad,
    isGated: !resumeUrlToLoad,
  });

  const currentResumeData = isDemo ? resumeDemoData : resumeData;
  const currentIconSet = Object.assign({}, DefaultIconSet, currentResumeConfig.iconSet);

  const { theme, onChangeTheme, isDarkMode, isForceDarkMode, onChangeDarkMode } = useTheme({
    resumeConfig: currentResumeConfig,
    applyBodyClass: true,
  });

  const hasResume = !!currentResumeData;

  const onResumeSelect = (value: ResumeSelectOption) => setSelectedResume(value);

  const { toolbarProps } = useToolbarConfig({
    defaultConfig: toolbarConfig,
    resumeData: currentResumeData,
    iconSet: currentIconSet,
    isDarkMode,
    isForceDarkMode,
    resumePDFUrl,
    hide: EnvConfig.hideToolbar || toolbarConfig?.hide || !hasResume,
    hideDemoOptions: toolbarConfig?.hideDemoOptions || !demo || !resumeDemoData,
  });

  return (
    <div className={classNames("cp-resume-app", { "cp-resume-fetching": isFetching, "cp-resume-fetched": isComplete })}>
      {isFailed && <div className="cp-resume-app-error">FAILED to load {resumeUrlToLoad}</div>}
      {demo && hasResume && (
        <div className="cp-resume-active-theme no-print display-none-mobile">
          <ResumeThemeSelect hideSelect={!demo} currentTheme={theme} onResumeThemeSelect={onChangeTheme} />
        </div>
      )}
      {hasResume && (
        <CPResume resumeData={currentResumeData} resumeConfig={currentResumeConfig} themeOverride={theme} />
      )}

      {demo && hasResume && theme === "base" && (
        <div className="cp-resume-demo">
          <CPResumeComponent
            resumeData={currentResumeData}
            className="no-print"
            resumeConfig={currentResumeConfig}
            themeOverride={theme}
          />
        </div>
      )}
      {hasResume && (
        <Toolbar onResumeSelect={onResumeSelect} onResumeDarkModeSelect={onChangeDarkMode} {...toolbarProps} />
      )}
    </div>
  );
};

export default ResumeApp;
