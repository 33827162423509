import { IconSetType, ResumePDFUrl } from "@/types/resume";
import classNames from "classnames";

type ToolbarPDFOptionLinkProps = {
  iconSet: IconSetType;
  resumePDFUrl?: ResumePDFUrl;
};

const ToolbarPDFOptionLink = ({ iconSet, resumePDFUrl }: ToolbarPDFOptionLinkProps) => {
  return (
    <a
      href={resumePDFUrl}
      title="Click to view Resume PDF"
      target="_blank"
      rel="noopener noreferrer"
      className="cp-resume-toolbar-option cp-resume-pdf"
    >
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.filePdf)} />
      <span className="sr-only">View PDF</span>
    </a>
  );
};

export default ToolbarPDFOptionLink;
