import "@/components/resume/links/Link.scss";

import { ComponentProps, JSX, ReactNode } from "react";

import classNames from "classnames";
import { isNil } from "@/utils";

export type LinkProps = ComponentProps<"a"> & {
  linkText?: ReactNode;
  isTextColor?: boolean;
};

const Link = ({
  href,
  linkText,
  isTextColor = true,
  target = "_blank",
  className,
  children,
  ...restProps
}: LinkProps): JSX.Element | null => {
  if (isNil(href) || isNil(linkText)) {
    return null;
  }

  return (
    <a
      className={classNames("cp-resume-link", className, { "cp-resume-link-text": isTextColor })}
      href={href}
      target={target}
      {...restProps}
    >
      {linkText} {children}
    </a>
  );
};

export default Link;
