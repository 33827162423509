import "@/components/resume/header/HeaderJobLabel.scss";
import { isEmpty, isNil } from "@/utils";

import { JSX } from "react";
import { LabelType } from "@/types/resume";

type HeaderJobLabelProps = {
  jobLabel?: LabelType;
};

const HeaderJobLabel = ({ jobLabel }: HeaderJobLabelProps): JSX.Element | null => {
  if (isNil(jobLabel) || isEmpty(jobLabel)) {
    return null;
  }

  return <span className="cp-resume-header-job-label">({jobLabel})</span>;
};

export default HeaderJobLabel;
