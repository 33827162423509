import { LocationType, ResumeMapApiType } from "@/types/resume";
import getMapUrl from "@/utils/getMapUrl";
import isEmpty from "@/utils/base/isEmpty";
import isNil from "@/utils/base/isNil";
import isString from "@/utils/base/isString";
import isUndefined from "@/utils/base/isUndefined";

const getResumeLocationUrl = (location?: LocationType, mapApi?: ResumeMapApiType) => {
  const isItemEmpty = (locationItem: unknown) => {
    return isNil(locationItem) || isUndefined(locationItem) || isEmpty(locationItem);
  };

  const mapParams: string[] = [];
  if (!isItemEmpty(location?.address) && isString(location?.address)) mapParams.push(location.address);
  if (!isItemEmpty(location?.city) && isString(location?.city)) mapParams.push(location.city);
  if (!isItemEmpty(location?.postalCode) && isString(location?.postalCode)) mapParams.push(location.postalCode);
  if (!isItemEmpty(location?.region) && isString(location?.region)) mapParams.push(location.region);
  if (!isItemEmpty(location?.countryCode) && isString(location?.countryCode)) mapParams.push(location.countryCode);

  return mapParams.length > 0 ? getMapUrl(mapParams.join(" "), mapApi) : undefined;
};

export default getResumeLocationUrl;
