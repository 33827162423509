import "@/components/resume/lists/awards/Awards.scss";
import { createKeyFrom, isArray, isNil } from "@/utils";
import AwardItem from "@/components/resume/lists/awards/AwardItem";
import { AwardsType } from "@/types/resume";
import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import { checkKeyViewable } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type AwardsProps = {
  awards?: AwardsType;
  header?: string;
};

const Awards = ({ awards = [], header = "Awards:" }: AwardsProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (!checkKeyViewable("awards", viewable) || !awards || isNil(awards) || (isArray(awards) && awards.length < 1)) {
    return null;
  }

  const heading = titles?.awards ?? header;

  return (
    <Section className="cp-resume-awards" heading={heading}>
      <ul className="cp-resume-awards-list">
        {awards.map((awardsItem) => {
          return awardsItem.hide ? null : <AwardItem key={createKeyFrom(awardsItem.title)} awardItem={awardsItem} />;
        })}
      </ul>
    </Section>
  );
};

export default Awards;
