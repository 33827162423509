import {
  DateMonthStringsType,
  IconSetType,
  ResumeConfigTitlesType,
  ResumeConfigType,
  ResumeMapApiType,
  ResumePipeItemType,
  ResumeThemeType,
  ResumeViewableType,
} from "@/types/resume";
import { DefaultIconSet, DefaultViewableSet } from "@/consts";
import { createContext } from "react";

type ResumeConfigContextType = {
  resumeConfig: ResumeConfigType;
  iconSet: IconSetType;
  titles?: ResumeConfigTitlesType;
  months?: DateMonthStringsType;
  viewable?: ResumeViewableType[];
  theme?: ResumeThemeType;
  pipes?: ResumePipeItemType;
  mapApi?: ResumeMapApiType;
};

const DefaultResumeConfig: ResumeConfigType = {
  theme: "base",
  iconSet: DefaultIconSet,
};

const DefaultResumeConfigContext: ResumeConfigContextType = {
  resumeConfig: DefaultResumeConfig,
  iconSet: DefaultIconSet,
  viewable: DefaultViewableSet,
};

const ResumeConfigContext = createContext<ResumeConfigContextType | null>(DefaultResumeConfigContext);
ResumeConfigContext.displayName = "ResumeConfigContext";

export default ResumeConfigContext;
