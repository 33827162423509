import "@/components/resume/dates/DateRange.scss";

import { ComponentProps, JSX } from "react";
import { DateMonthStringsType, DateRangeType } from "@/types/resume";
import { isEmpty, isNil } from "@/utils";
import { DateMonthStringsSet } from "@/consts";
import EndDate from "@/components/resume/dates/EndDate";
import StartDate from "@/components/resume/dates/StartDate";
import classNames from "classnames";

type DateRangeProps = DateRangeType &
  ComponentProps<"span"> & {
    months?: DateMonthStringsType;
  };

const DateRange = ({
  startDate,
  endDate = "",
  months = DateMonthStringsSet,
  className,
}: DateRangeProps): JSX.Element | null => {
  if (isEmpty(startDate) || (isNil(startDate) && (isNil(endDate) || isEmpty(endDate)))) {
    return null;
  }

  return (
    <span className={classNames("cp-resume-date-range", className)}>
      <StartDate date={startDate} months={months} /> - <EndDate date={endDate} months={months} />
    </span>
  );
};

export default DateRange;
