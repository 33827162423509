import { ComponentProps, JSX } from "react";
import { DateMonthStringsSet } from "@/consts";
import { DateTextType } from "@/types/resume";
import DateTime from "@/components/resume/dates/DateTime";
import classNames from "classnames";

type EndDateProps = ComponentProps<"time"> &
  DateTextType & {
    noEnd?: string;
  };

const EndDate = ({ date, noEnd = "Present", months = DateMonthStringsSet, className }: EndDateProps): JSX.Element => {
  return <DateTime className={classNames("cp-end-date", className)} date={date} noDate={noEnd} months={months} />;
};

export default EndDate;
