import "@/components/toolbar/Toolbar.scss";

import { FocusEventHandler, useRef, useState } from "react";
import { ResumeSelectOption, ToolbarConfigType } from "@/types/base";

import JSONPretty from "react-json-pretty";
import ResumeDarkModeSelect from "@/components/form/ResumeDarkModeSelect.tsx";
import ResumeSelect from "@/components/form/ResumeSelect.tsx";
import { ResumeThemeType } from "@/types/resume";
import ToolbarBuiltWith from "@/components/toolbar/ToolbarBuiltWith.tsx";
import ToolbarJsonToggleOptionBtn from "@/components/toolbar/ToolbarJsonToggleOptionBtn.tsx";
import ToolbarPDFOptionLink from "@/components/toolbar/ToolbarPDFOptionLink.tsx";
import ToolbarPrintOptionBtn from "@/components/toolbar/ToolbarPrintOptionBtn.tsx";
import classNames from "classnames";
import { clipElement } from "@/utils";

type ToolbarProps = ToolbarConfigType & {
  onResumeSelect?: (whichResume: ResumeSelectOption) => void;
  onResumeDarkModeSelect?: (whichTheme: ResumeThemeType) => void;
};

const Toolbar = ({
  resumeData,
  resumePDFUrl,
  iconSet,
  hide = false,
  hideDemoOptions = false,
  hideFullResumeOption = false,
  hideResumeOption = false,
  hideResumeSelect = false,
  hideDarkModeSelect = false,
  hideJson = false,
  hidePDF = false,
  hidePrint = false,
  hideBuiltWith = false,
  jsonCopy = false,
  defaultJsonVisible = false,
  isDarkMode = false,
  isForceDarkMode = false,
  onResumeSelect,
  onResumeDarkModeSelect,
}: ToolbarProps) => {
  const [jsonVisible, setJsonVisible] = useState(defaultJsonVisible ?? false);
  const clipRef = useRef<HTMLDivElement>(null);

  if (hide || !resumeData || (hideBuiltWith && hidePDF && hidePrint && hideJson)) {
    return null;
  }

  const onJsonFocus: FocusEventHandler = () => {
    return clipRef?.current && clipElement(clipRef?.current);
  };

  const onToggleJsonClick = () => {
    if (!jsonVisible) clipRef?.current?.focus();
    setJsonVisible(!jsonVisible);
  };

  return (
    <div
      className={classNames("cp-resume-toolbar", {
        "cp-resume-toolbar-json-visible": jsonVisible,
        "cp-resume-toolbar-json-hidden": !jsonVisible,
      })}
    >
      <footer className="cp-resume-toolbar-footer">
        <div
          className="cp-resume-toolbar-json outline"
          ref={clipRef}
          tabIndex={-1}
          onFocus={jsonCopy ? onJsonFocus : undefined}
          aria-label={`A resume for ${resumeData?.basics?.name ?? "no one"} in JSON format.`}
        >
          <JSONPretty id="cp-resume-toolbar-json-pretty" json={resumeData} />
        </div>
        <nav className="cp-resume-toolbar-footer-nav">
          {!hideBuiltWith && <ToolbarBuiltWith />}
          <div className="cp-resume-toolbar-options">
            <ResumeDarkModeSelect
              hideSelect={hideDarkModeSelect}
              onResumeDarkModeSelect={onResumeDarkModeSelect}
              className="cp-resume-toolbar-option display-none-mobile"
              value={isDarkMode ? (isForceDarkMode ? "force-dark" : "supports-dark") : ""}
            />
            <ResumeSelect
              hideSelect={hideResumeSelect}
              hideResumeOption={hideResumeOption}
              hideFullResumeOption={hideFullResumeOption}
              hideDemoOptions={hideDemoOptions}
              onResumeSelect={onResumeSelect}
              className="cp-resume-toolbar-option"
            />
            {(!hidePDF || !resumePDFUrl) && <ToolbarPDFOptionLink iconSet={iconSet} resumePDFUrl={resumePDFUrl} />}
            {!hidePrint && <ToolbarPrintOptionBtn iconSet={iconSet} />}
            {!hideJson && (
              <ToolbarJsonToggleOptionBtn jsonVisible={jsonVisible} onClick={onToggleJsonClick} iconSet={iconSet} />
            )}
          </div>
        </nav>
      </footer>
    </div>
  );
};

export default Toolbar;
