import { DefaultMapApi } from "@/consts";

const getMapUrl = (locationQuery: string, mapApi = DefaultMapApi) => {
  //https://developers.google.com/maps/documentation/urls/get-started#search-action
  //https://www.google.com/maps/search/?api=1&query=(URLEncodedString)

  return `${mapApi}${encodeURIComponent(locationQuery)}`;
};

export default getMapUrl;
