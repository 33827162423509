import { ResumeConfigType, ResumeThemeType } from "@/types/resume";
import { useEffect, useState } from "react";
import includesSupportedTheme from "@/utils/includesSupportedTheme";
import isArray from "@/utils/base/isArray";
import isEmpty from "@/utils/base/isEmpty";
import isString from "@/utils/base/isString";
import isSupportedTheme from "@/utils/isSupportedTheme";

type UseThemeProps = {
  resumeConfig?: ResumeConfigType;
  applyBodyClass?: boolean;
  darkThemeClassName?: string;
  forceDarkThemeClassName?: string;
  applyCustomLink?: boolean;
};

type UseThemeReturnProps = {
  theme?: ResumeThemeType;
  isDarkMode: boolean;
  isForceDarkMode: boolean;
  onChangeTheme?: (theme: ResumeThemeType) => void;
  onChangeDarkMode?: (theme: ResumeThemeType) => void;
};
const useTheme = ({
  resumeConfig,
  applyBodyClass = false,
  darkThemeClassName = "cp-resume-app-dark",
  forceDarkThemeClassName = "cp-resume-app-force-dark",
  applyCustomLink = true,
}: UseThemeProps): UseThemeReturnProps => {
  const [theme, setTheme] = useState<ResumeThemeType | undefined>(resumeConfig?.theme);
  const isDarkMode = includesSupportedTheme({ theme, themeSearch: "dark" });
  const isForceDarkMode = includesSupportedTheme({ theme, themeSearch: "force-dark" });
  const customThemeCssLinkClassName = "customThemeCssLink";

  const onChangeDarkMode = (modeTheme: ResumeThemeType) => {
    let updatedTheme: ResumeThemeType | undefined = isArray(theme)
      ? [...theme].filter((t) => !(t.includes("force-dark") || t.includes("supports-dark")))
      : theme?.replace("force-dark", "").replace("supports-dark", "");

    if (!isEmpty(modeTheme) && isArray(updatedTheme)) updatedTheme.push(modeTheme as string);

    if (isString(updatedTheme)) {
      updatedTheme = modeTheme;
    }

    setTheme(updatedTheme);
  };

  const appendCustomThemeCSS = (themeStr?: string) => {
    if (isString(themeStr) && !isSupportedTheme(themeStr)) {
      const customThemeCssLinkID = `custom-resume-theme-${themeStr}`;
      if (!document.getElementById(customThemeCssLinkID)) {
        const customThemeCssLink = document.createElement("link");
        customThemeCssLink.rel = "stylesheet";
        customThemeCssLink.href = `./themes/${themeStr}.css`;
        customThemeCssLink.id = customThemeCssLinkID;
        customThemeCssLink.className = customThemeCssLinkClassName;
        document.head.append(customThemeCssLink);
      }
    }
  };

  useEffect(() => {
    if (resumeConfig?.theme) setTheme(resumeConfig.theme);
  }, [resumeConfig]);

  useEffect(() => {
    if (applyBodyClass) {
      const { classList } = document.body;

      if (isDarkMode) {
        classList.add(darkThemeClassName);
      } else {
        classList.remove(darkThemeClassName);
      }

      if (isForceDarkMode) {
        classList.add(forceDarkThemeClassName);
      } else {
        classList.remove(forceDarkThemeClassName);
      }
    }
  }, [theme, applyBodyClass, isDarkMode, isForceDarkMode, darkThemeClassName, forceDarkThemeClassName]);

  useEffect(() => {
    //Remove any existing custom themes previously loaded
    document.querySelectorAll(`.${customThemeCssLinkClassName}`).forEach((el) => el.remove());

    const hasCustom =
      theme &&
      (isArray(theme) ? theme.filter((cTheme) => !isSupportedTheme(cTheme)).length > 0 : !isSupportedTheme(theme));

    if (hasCustom) {
      if (isString(theme)) {
        appendCustomThemeCSS(theme);
      } else if (isArray(theme)) {
        theme.forEach((themeStr) => appendCustomThemeCSS(themeStr));
      }
    }
  }, [theme, applyCustomLink]);

  return {
    theme,
    isDarkMode,
    isForceDarkMode,
    onChangeTheme: (value: ResumeThemeType) => setTheme(value),
    onChangeDarkMode,
  };
};

export default useTheme;
