import "@/components/resume/lists/projects/ProjectItem.scss";
import { checkKeyViewable, isNil } from "@/utils";

import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import { JSX } from "react";
import Link from "@/components/resume/links/Link";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { ProjectItemType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type ProjectItemProps = {
  projectItem: ProjectItemType;
};

const ProjectItem = ({ projectItem }: ProjectItemProps): JSX.Element | null => {
  const { months, pipes, viewable } = useResumeConfig();
  if (isNil(projectItem) || projectItem.hide) {
    return null;
  }
  const showDescription = checkKeyViewable("project.description", viewable);
  const showHighlights = checkKeyViewable("project.highlights", viewable);

  return (
    <li className="cp-resume-project-item">
      <Pipes className="cp-resume-project-info" blockOnMobile={true}>
        <PipeItem
          itemValue={
            projectItem.url ? (
              <Link href={projectItem.url} linkText={projectItem.name} target="_blank" />
            ) : (
              projectItem.name
            )
          }
          itemClassName="cp-resume-project-name"
          itemType={pipes}
        />
        <PipeItem
          itemValue={<DateRange startDate={projectItem.startDate} endDate={projectItem.endDate} months={months} />}
          itemClassName="cp-resume-project-dates"
          itemType={pipes}
        />
        <PipeItem
          itemValue={showDescription && projectItem.description ? projectItem.description : undefined}
          itemClassName="cp-resume-project-description"
          itemType={pipes}
        />
      </Pipes>
      {showHighlights && <HighlightsList highlights={projectItem.highlights} />}
    </li>
  );
};

export default ProjectItem;
