import "@/components/resume/sections/Basics.scss";

import { checkKeyViewable, isEmpty, isNil, stripProtocol, transformBase64, useLocation } from "@/utils";

import { BasicsType } from "@/types/resume";
import IconWithText from "@/components/resume/icons/IconWithText";
import { JSX } from "react";
import Link from "@/components/resume/links/Link";
import Location from "@/components/resume/locations/Location";
import MailtoLink from "@/components/resume/links/MailtoLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import Profiles from "@/components/resume/lists/profiles/Profiles";
import TelLink from "@/components/resume/links/TelLink";
import classNames from "classnames";
import { useResumeConfig } from "@/context/resume";

type BasicsProps = {
  header?: string;
  basics?: BasicsType;
};

const Basics = ({ basics, header = "Contact Info:" }: BasicsProps): JSX.Element | null => {
  const { iconSet, mapApi, pipes, titles, viewable = [] } = useResumeConfig();
  const showBasics = checkKeyViewable("basics", viewable);
  const showLocation = checkKeyViewable("basics.location", viewable);
  const showUrl = checkKeyViewable("basics.url", viewable);
  const showPhone = checkKeyViewable("basics.phone", viewable);
  const showEmail = checkKeyViewable("basics.email", viewable);
  const showProfiles = checkKeyViewable("basics.profiles", viewable);
  const showImage = checkKeyViewable("basics.image", viewable);

  const heading = titles?.basics ?? header;

  const {
    hasLocation = false,
    hasPhone: hasLocationPhone = false,
    location,
  } = useLocation({ showLocation, location: basics?.location, mapApi });

  if (!showBasics || isNil(basics) || isEmpty(basics) || isNil(iconSet) || isEmpty(iconSet)) {
    return null;
  }

  const email = showEmail ? transformBase64({ toTransform: basics?.email }) : undefined;
  const phone = showPhone ? transformBase64({ toTransform: basics?.phone }) : undefined;
  const locationPhone = hasLocationPhone ? transformBase64({ toTransform: location?.phone }) : undefined;

  return (
    <div
      className={classNames("cp-resume-basics", {
        "cp-resume-basics-inline": !showLocation,
      })}
    >
      <div className="cp-resume-basics-wrapper">
        {showImage && basics.image && (
          <img src={basics.image} className="cp-resume-basics-image" alt={`${basics.name}`} />
        )}
        <div className="cp-resume-basics-detail">
          <h2 className="cp-resume-basics-header sr-only">{heading}</h2>
          <Pipes className="cp-resume-basics-row cp-resume-basics-contact-row" blockOnMobile={true}>
            {showPhone && phone && (
              <PipeItem
                itemValue={<TelLink iconSet={iconSet} target="_blank" phone={phone} />}
                itemClassName="cp-resume-basics-phone"
                itemType={pipes}
              />
            )}
            {showEmail && email && (
              <PipeItem
                itemValue={
                  <MailtoLink
                    email={email}
                    linkText={<IconWithText iconText="Email: " iconClassName={iconSet.envelope} text={email} />}
                  />
                }
                itemClassName="cp-resume-basics-email"
                itemType={pipes}
              />
            )}

            {showUrl && basics.url && (
              <PipeItem
                itemValue={
                  <Link
                    href={basics.url}
                    linkText={
                      <IconWithText
                        iconAriaHidden={true}
                        iconClassName={iconSet.link}
                        text={stripProtocol(basics.url)}
                      />
                    }
                  />
                }
                itemClassName="cp-resume-basics-website"
                itemType={pipes}
              />
            )}
          </Pipes>

          {showLocation && hasLocation && (
            <Pipes className="cp-resume-basics-row cp-resume-basics-location-row">
              <PipeItem
                itemValue={<Location iconSet={iconSet} location={location} viewable={viewable} asLink={true} />}
                itemClassName="cp-resume-basics-location"
                itemType={pipes}
              />
              {hasLocationPhone && locationPhone && (
                <PipeItem
                  itemValue={
                    <TelLink
                      iconSet={iconSet}
                      target="_blank"
                      phone={locationPhone}
                      className="cp-resume-location-item cp-resume-location-item-phone"
                    />
                  }
                  itemClassName="cp-resume-basics-location-phone"
                  itemType={pipes}
                />
              )}
            </Pipes>
          )}

          {showProfiles && (
            <Profiles className="cp-resume-basics-row cp-resume-basics-profile-row" profiles={basics?.profiles} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Basics;
