import { AwardItemType } from "@/types/resume";
import DateTime from "@/components/resume/dates/DateTime";
import { JSX } from "react";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type AwardItemProps = {
  awardItem: AwardItemType;
};

const AwardItem = ({ awardItem }: AwardItemProps): JSX.Element | null => {
  const { months, pipes } = useResumeConfig();

  if (isNil(awardItem) || awardItem.hide) {
    return null;
  }

  return (
    <li className="cp-resume-award-item">
      <Pipes className="cp-resume-award-info" blockOnMobile={true}>
        <PipeItem itemValue={awardItem.title} itemClassName="cp-resume-award-title" itemType={pipes} />
        <PipeItem
          itemValue={<DateTime date={awardItem.date} months={months} />}
          itemClassName="cp-resume-award-date"
          itemType={pipes}
        />
        <PipeItem itemValue={awardItem.awarder} itemClassName="cp-resume-award-awarder" itemType={pipes} />
        <PipeItem itemValue={awardItem.summary} itemClassName="cp-resume-award-summary" itemType={pipes} />
      </Pipes>
    </li>
  );
};

export default AwardItem;
