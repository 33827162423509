import "@/components/resume/lists/languages/Languages.scss";
import { checkKeyViewable, createKeyFrom, isArray, isNil } from "@/utils";

import { JSX } from "react";
import LanguageItem from "@/components/resume/lists/languages/LanguageItem";
import { LanguagesType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type LanguagesProps = {
  languages?: LanguagesType;
  header?: string;
};

const Languages = ({ languages = [], header = "Languages:" }: LanguagesProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    checkKeyViewable("languages", viewable) ||
    !languages ||
    isNil(languages) ||
    (isArray(languages) && languages.length < 1)
  ) {
    return null;
  }
  const heading = titles?.languages ?? header;

  return (
    <Section className="cp-resume-languages" heading={heading}>
      <ul className="cp-resume-languages-list">
        {languages.map((language) => {
          return language.hide ? null : <LanguageItem key={createKeyFrom(language.language)} languageItem={language} />;
        })}
      </ul>
    </Section>
  );
};

export default Languages;
