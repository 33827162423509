import "@/components/resume/lists/pipes/PipeItem.scss";
import { isEmpty, isNil, isString } from "@/utils";

import { JSX } from "react";
import { ResumePipeItemType } from "@/types/resume";
import classNames from "classnames";

type PipeItemProps = {
  itemValue?: string | JSX.Element | null | undefined;
  itemClassName?: string;
  itemType?: ResumePipeItemType;
};

const PipeItem = ({ itemValue, itemClassName, itemType = "bullet" }: PipeItemProps): JSX.Element | null => {
  if (isNil(itemValue) || (isString(itemValue) && isEmpty(itemValue))) {
    return null;
  }

  return (
    <li
      className={classNames("cp-resume-pipe-item", itemClassName, {
        [`cp-resume-pipe-item-${itemType}`]: !!itemType,
      })}
    >
      {itemValue}
    </li>
  );
};

export default PipeItem;
