import DefaultIconSet from "@/consts/DefaultIconSet";
import DefaultMapApi from "@/consts/DefaultMapApi";
import DefaultViewableSet from "@/consts/DefaultViewableSet";
import { ResumeConfigType } from "@/types/resume";

const DefaultResumeConfig: ResumeConfigType = {
  iconSet: DefaultIconSet,
  mapApi: DefaultMapApi,
  viewable: DefaultViewableSet,
};

export default DefaultResumeConfig;
