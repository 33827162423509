import "@/components/resume/sections/Summary.scss";
import { isEmpty, isNil } from "@/utils";

import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import { SummaryType } from "@/types/resume";

type SummaryProps = {
  summaryText?: SummaryType;
};

const Summary = ({ summaryText }: SummaryProps): JSX.Element | null => {
  if (isNil(summaryText) || isEmpty(summaryText)) {
    return null;
  }

  return (
    <Section className="cp-resume-summary" heading="Summary:">
      <p className="cp-resume-summary-text">{summaryText}</p>
    </Section>
  );
};

export default Summary;
