import { JSX } from "react";
import { SkillType } from "@/types/resume";
import { isNil } from "@/utils";

type SkillItemProps = {
  skill?: SkillType;
};

const SkillItem = ({ skill }: SkillItemProps): JSX.Element | null => {
  if (isNil(skill) || !skill?.name || skill.hide) {
    return null;
  }

  return (
    <li className="cp-resume-skill">
      <div className="cp-resume-skill-name" title={skill.name}>
        {skill.name}
      </div>
      <div className="cp-resume-skill-level">{skill.level}</div>
    </li>
  );
};

export default SkillItem;
