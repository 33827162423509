import "@/components/resume/lists/work/Work.scss";

import { checkKeyViewable, createKeyFrom, isArray, isNil } from "@/utils";

import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import WorkItem from "@/components/resume/lists/work/WorkItem";
import { WorkType } from "@/types/resume";
import { useResumeConfig } from "@/context/resume";

type WorkProps = {
  work?: WorkType;
  header?: string;
};

const Work = ({ work = [], header = "Work:" }: WorkProps): JSX.Element | null => {
  const { titles, viewable = [] } = useResumeConfig();

  if (!checkKeyViewable("work", viewable) || !work || isNil(work) || (isArray(work) && work.length < 1)) {
    return null;
  }
  const heading = titles?.work ?? header;

  return (
    <Section className="cp-resume-work" heading={heading}>
      <ul className="cp-resume-work-list">
        {work.map((workItem) => {
          return workItem.hide ? null : (
            <WorkItem
              key={createKeyFrom(`${workItem.name} ${workItem.startDate} ${workItem.endDate}`)}
              workItem={workItem}
            />
          );
        })}
      </ul>
    </Section>
  );
};

export default Work;
