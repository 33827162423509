import { JSX } from "react";
import { LanguageItemType } from "@/types/resume";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type LanguageItemProps = {
  languageItem: LanguageItemType;
};

const LanguageItem = ({ languageItem }: LanguageItemProps): JSX.Element | null => {
  const { pipes } = useResumeConfig();

  if (isNil(languageItem) || languageItem.hide) {
    return null;
  }

  return (
    <li className="cp-resume-language-item">
      <Pipes className="cp-resume-language-info" blockOnMobile={true}>
        <PipeItem itemValue={languageItem.language} itemClassName="cp-resume-language-name" itemType={pipes} />
        <PipeItem itemValue={languageItem.fluency} itemClassName="cp-resume-language-fluency" itemType={pipes} />
      </Pipes>
    </li>
  );
};

export default LanguageItem;
