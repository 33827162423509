import { ResumeThemeType } from "@/types/resume";
import isArray from "@/utils/base/isArray";
import isString from "@/utils/base/isString";
import isSupportedTheme from "@/utils/isSupportedTheme";

type IncludesSupportedThemeProps = {
  theme?: ResumeThemeType;
  themeSearch: string;
};

const includesSupportedTheme = ({ theme, themeSearch }: IncludesSupportedThemeProps): boolean => {
  const isSupportedThemeString = (themeStr: string) =>
    isSupportedTheme(themeStr) && (themeStr.includes(themeSearch) || themeStr === themeSearch);
  const isSupportedThemeArray = isArray(theme) && theme.filter((cTheme) => isSupportedThemeString(cTheme)).length > 0;
  return isSupportedThemeArray || (isString(theme) && isSupportedThemeString(theme));
};

export default includesSupportedTheme;
