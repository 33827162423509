import { Children, ReactElement, ReactNode, isValidElement } from "react";
import isEmpty from "@/utils/base/isEmpty";
import isNumber from "@/utils/base/isNumber";
import isString from "@/utils/base/isString";

const getValidChildren = (nodes: ReactNode) => {
  return Children.toArray(nodes).filter(
    (child) => isNumber(child) || (isString(child) && !isEmpty(child)) || (!isString(child) && isValidElement(child))
  ) as ReactElement[];
};

export default getValidChildren;
