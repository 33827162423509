import { IconSetType } from "@/types/resume";
import classNames from "classnames";

type ToolbarPrintOptionBtnProps = {
  iconSet: IconSetType;
};

const ToolbarPrintOptionBtn = ({ iconSet }: ToolbarPrintOptionBtnProps) => {
  const onClick = () => {
    window.print();
  };
  return (
    <button onClick={onClick} title="Click to print Resume" className="cp-resume-toolbar-option cp-resume-print">
      <i className={classNames("cp-resume-toolbar-option-icon", iconSet.print)} />
      <span className="sr-only">Print Resume</span>
    </button>
  );
};

export default ToolbarPrintOptionBtn;
