import { checkKeyViewable, createKeyFrom, isArray, isNil } from "@/utils";

import EducationItem from "@/components/resume/lists/education/EducationItem";
import { EducationType } from "@/types/resume";
import { JSX } from "react";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type EducationProps = {
  education?: EducationType;
  header?: string;
};

const Education = ({ education, header = "Education:" }: EducationProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("education", viewable) ||
    !education ||
    isNil(education) ||
    (isArray(education) && education.length < 1)
  ) {
    return null;
  }
  const heading = titles?.education ?? header;

  return (
    <Section className="cp-resume-education" heading={heading}>
      {education.length > 0 && (
        <ul className="cp-resume-education-list">
          {education.map((educationItem) => {
            return educationItem.hide ? null : (
              <EducationItem key={createKeyFrom(educationItem.institution)} education={educationItem} />
            );
          })}
        </ul>
      )}
    </Section>
  );
};

export default Education;
