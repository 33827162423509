import "@/components/resume/header/Header.scss";

import { JSX, ReactNode, useEffect } from "react";
import HeaderJobLabel from "@/components/resume/header/HeaderJobLabel";
import { LabelType } from "@/types/resume";

type GetDocTitleProps = {
  jobLabel?: LabelType;
  fullName?: string;
};

const getResumeTitle = ({ fullName, jobLabel }: GetDocTitleProps) => {
  const jobLabelText = jobLabel ? ` (${jobLabel})` : "";
  return `${fullName}${jobLabelText} - Resume`;
};

type HeaderProps = {
  jobLabel?: LabelType;
  fullName?: string;
  contactInfo?: ReactNode;
};

const Header = ({ jobLabel, fullName, contactInfo }: HeaderProps): JSX.Element | null => {
  useEffect(() => {
    document.title = getResumeTitle({ fullName, jobLabel });
  }, [jobLabel, fullName]);

  return (
    <header className="cp-resume-header">
      <h1 className="cp-resume-title">
        {fullName} {!!jobLabel && <HeaderJobLabel jobLabel={jobLabel} />}
      </h1>

      {contactInfo}
    </header>
  );
};

export default Header;
