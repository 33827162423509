import "@/components/form/ResumeThemeSelect.scss";

import { ChangeEventHandler, ComponentProps, JSX } from "react";
import { ResumeThemeOptionItem, ResumeThemeType } from "@/types/resume";
import { DefaultResumeThemeOptions } from "@/consts";
import classNames from "classnames";
import { isArray } from "@/utils";

type ResumeThemeSelectProps = ComponentProps<"select"> & {
  hideSelect?: boolean;
  currentTheme?: ResumeThemeType;
  resumeThemeOptions?: ResumeThemeOptionItem[];
  onResumeThemeSelect?: (value: ResumeThemeType) => void;
  className?: string;
};

const ResumeThemeSelect = ({
  hideSelect = true,
  currentTheme,
  resumeThemeOptions = DefaultResumeThemeOptions,
  onResumeThemeSelect,
  className,
  ...restProps
}: ResumeThemeSelectProps): JSX.Element | null => {
  if (hideSelect) {
    return null;
  }

  const onResumeThemeSelectChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selected = e.target.value as ResumeThemeType;
    return onResumeThemeSelect && onResumeThemeSelect(selected);
  };

  return (
    <>
      <div
        className={classNames("cp-resume-theme-selected", {
          "cp-resume-theme-selected-dark-text": currentTheme === "full-wide",
        })}
      >
        <strong>Selected Theme(s):</strong> <em>{isArray(currentTheme) ? currentTheme.join(", ") : currentTheme}</em>
      </div>
      <select
        {...restProps}
        name="theme-selector"
        className={classNames("cp-resume-theme-select", className, {
          "cp-resume-theme-select-dark-bg": currentTheme === "full-wide" || currentTheme?.includes("full-wide"),
        })}
        value={isArray(currentTheme) ? currentTheme[0] : currentTheme}
        onChange={onResumeThemeSelectChange}
      >
        {resumeThemeOptions.map((option) => {
          if (option.value === "side-scroll") {
            return null;
          }
          return (
            <option value={option.value} key={`theme-selector-option-${option.value}`}>
              {option.text}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default ResumeThemeSelect;
