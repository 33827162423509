import { EnvConfig } from "@/consts";
import { ResumeUrl } from "@/types/resume";
import isEmpty from "@/utils/base/isEmpty";
import isNull from "@/utils/base/isNull";
import isUndefined from "@/utils/base/isUndefined";

type GetResumeUrlProps = {
  fromUrl?: string;
  resumeUrl: string;
  basePath?: string;
};

const DefaultFromUrl = window.location.href;

const getResumeUrlFromHref = ({
  fromUrl = DefaultFromUrl,
  resumeUrl,
  basePath = EnvConfig.basePath,
}: GetResumeUrlProps): ResumeUrl => {
  if (isEmpty(fromUrl) || isNull(fromUrl) || isUndefined(fromUrl)) {
    return resumeUrl;
  }

  if (resumeUrl.startsWith("http")) {
    return resumeUrl;
  }

  const url = new URL(fromUrl);
  const basePathSlashLength = basePath.replace("://", "").split("/").length;
  const pathIndex = basePathSlashLength > 0 ? basePathSlashLength - 2 : -1;
  const pathnameParts = url.pathname.split("/").filter(Boolean);
  const hrefResumeFileName = pathIndex >= 0 && pathnameParts.length > 0 ? pathnameParts[pathIndex] : undefined;

  return hrefResumeFileName ? `/data/${hrefResumeFileName}.json` : resumeUrl;
};

export default getResumeUrlFromHref;
