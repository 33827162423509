import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import { JSX } from "react";
import Link from "@/components/resume/links/Link";
import LocationLink from "@/components/resume/locations/LocationLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { WorkItemType } from "@/types/resume";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type WorkItemProps = {
  workItem: WorkItemType;
};

const WorkItem = ({ workItem }: WorkItemProps): JSX.Element | null => {
  const { iconSet, mapApi, months, pipes } = useResumeConfig();
  if (isNil(workItem) || workItem.hide) {
    return null;
  }

  return (
    <li className="cp-resume-work-item">
      <Pipes className="cp-resume-work-info" blockOnMobile={true}>
        <PipeItem itemValue={workItem.position} itemClassName="cp-resume-work-position" itemType={pipes} />
        <PipeItem
          itemValue={
            <DateRange
              startDate={workItem.startDate}
              endDate={workItem.endDate}
              months={months}
              className="cp-resume-work-dates"
            />
          }
          itemClassName="cp-resume-work-dates"
          itemType={pipes}
        />
        <PipeItem
          itemValue={
            workItem.url ? <Link href={workItem.url} linkText={workItem.name} target="_blank" /> : workItem.name
          }
          itemClassName="cp-resume-work-name"
          itemType={pipes}
        />
        <PipeItem itemValue={workItem.summary} itemClassName="cp-resume-work-summary" itemType={pipes} />
        <PipeItem
          itemValue={
            <LocationLink iconSet={iconSet} locationQuery={workItem.location} mapApi={mapApi} iconPlacement={"end"} />
          }
          itemClassName="cp-resume-work-location"
          itemType={pipes}
        />
      </Pipes>
      <HighlightsList highlights={workItem.highlights} />
    </li>
  );
};

export default WorkItem;
