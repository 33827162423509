import CPResume, { CPResumeProps } from "@/components/resume/CPResume";
import { IconSetType, ResumeConfigType, ResumeType } from "@/types/resume";
import { Component } from "react";
import classNames from "classnames";

type CPResumeComponentProps = CPResumeProps;

class CPResumeComponent extends Component<
  CPResumeComponentProps,
  { resumeData: ResumeType; resumeConfig: ResumeConfigType; iconSet: IconSetType }
> {
  constructor(props: CPResumeComponentProps) {
    super(props);
  }

  render() {
    return <CPResume {...this.props} className={classNames("cp-resume-component", this.props.className)} />;
  }
}

export default CPResumeComponent;
