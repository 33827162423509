import { ResumeSelectOption, ResumeSelectOptionItem } from "@/types/base";

const buildResumeSelectOption = (value: ResumeSelectOption, text: string): ResumeSelectOptionItem => ({ value, text });

type BuildResumeSelectOptionsProps = {
  noOptions?: boolean;
  hideResumeOption?: boolean;
  hideFullResumeOption?: boolean;
  hideDemoOptions?: boolean;
};

const buildResumeSelectOptions = ({
  noOptions = false,
  hideResumeOption = false,
  hideFullResumeOption = false,
  hideDemoOptions = true,
}: BuildResumeSelectOptionsProps) => {
  const resumeSelectOptions: ResumeSelectOptionItem[] = [];

  if (!noOptions) {
    if (!hideResumeOption) resumeSelectOptions.push(buildResumeSelectOption("res-base-config", "Resume"));
    if (!hideFullResumeOption) resumeSelectOptions.push(buildResumeSelectOption("res-full-config", "Resume Full"));
    if (!hideDemoOptions)
      resumeSelectOptions.push(
        buildResumeSelectOption("demo-res-base-config", "Demo"),
        buildResumeSelectOption("demo-res-full-config", "Demo Full")
      );
  }
  return resumeSelectOptions;
};

export default buildResumeSelectOptions;
