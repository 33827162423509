import { JSX } from "react";
import { LinkProps } from "@/components/resume/links/Link";
import ObscureLink from "@/components/resume/links/ObscureLink";
import classNames from "classnames";
import { isNil } from "@/utils";

type MailtoLinkProps = Omit<LinkProps, "href"> & {
  email?: string;
};

const MailtoLink = ({
  email,
  className,
  linkText = "link",
  target = "_blank",
  ...restProps
}: MailtoLinkProps): JSX.Element | null => {
  if (!email || isNil(email)) {
    return null;
  }
  const mailtoLinkHref = `mailto:${email}`;

  return (
    <ObscureLink
      className={classNames("cp-resume-mailto-link", className)}
      href={mailtoLinkHref}
      linkText={linkText}
      target={target}
      {...restProps}
    />
  );
};

export default MailtoLink;
