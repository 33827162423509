import DateRange from "@/components/resume/dates/DateRange";
import HighlightsList from "@/components/resume/lists/highlights/HighlightsList";
import { JSX } from "react";
import LocationLink from "@/components/resume/locations/LocationLink";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { VolunteerItemType } from "@/types/resume";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type VolunteerItemProps = {
  volunteerItem: VolunteerItemType;
};

const VolunteerItem = ({ volunteerItem }: VolunteerItemProps): JSX.Element | null => {
  const { iconSet, mapApi, months, pipes } = useResumeConfig();

  if (isNil(volunteerItem) || volunteerItem.hide) {
    return null;
  }

  return (
    <li className="cp-resume-volunteer-item">
      <Pipes className="cp-resume-volunteer-info" blockOnMobile={true}>
        <PipeItem itemValue={volunteerItem.position} itemClassName="cp-resume-volunteer-position" itemType={pipes} />
        <PipeItem
          itemValue={
            <DateRange
              startDate={volunteerItem.startDate}
              endDate={volunteerItem.endDate}
              months={months}
              className="cp-resume-volunteer-dates"
            />
          }
          itemClassName="cp-resume-volunteer-dates"
          itemType={pipes}
        />
        <PipeItem
          itemValue={volunteerItem.organization}
          itemClassName="cp-resume-volunteer-company-organization"
          itemType={pipes}
        />
        <PipeItem
          itemValue={
            <LocationLink
              iconSet={iconSet}
              locationQuery={volunteerItem.location}
              mapApi={mapApi}
              iconPlacement={"end"}
            />
          }
          itemClassName="cp-resume-volunteer-company-location"
          itemType={pipes}
        />
      </Pipes>
      <HighlightsList highlights={volunteerItem.highlights} />
    </li>
  );
};

export default VolunteerItem;
