import { ComponentProps, JSX } from "react";
import { isDate, isEmpty, isNil } from "@/utils";

import { DateMonthStringsSet } from "@/consts";
import { DateTextType } from "@/types/resume";
import classNames from "classnames";

type DateTimeProps = ComponentProps<"time"> &
  DateTextType & {
    noDate?: string;
  };

const DateTime = ({
  date,
  noDate = "Not Started",
  className,
  months = DateMonthStringsSet,
}: DateTimeProps): JSX.Element | string => {
  if (isEmpty(date) || isNil(date)) {
    return noDate;
  }

  const dateObj = new Date(date);
  const dateConverted = !isDate(dateObj) ? noDate : `${months[dateObj.getUTCMonth()]}/${dateObj.getUTCFullYear()}`;

  return <time className={classNames("cp-date", className)}>{dateConverted}</time>;
};

export default DateTime;
