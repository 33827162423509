import { InterestItemType } from "@/types/resume";
import { JSX } from "react";
import PipeItem from "@/components/resume/lists/pipes/PipeItem";
import Pipes from "@/components/resume/lists/pipes/Pipes";
import { isNil } from "@/utils";
import { useResumeConfig } from "@/context/resume";

type InterestItemProps = {
  interestItem: InterestItemType;
};

const InterestItem = ({ interestItem }: InterestItemProps): JSX.Element | null => {
  const { pipes } = useResumeConfig();

  if (isNil(interestItem) || interestItem.hide) {
    return null;
  }

  return (
    <li className="cp-resume-interest-item">
      <Pipes className="cp-resume-interest-info" blockOnMobile={true}>
        <PipeItem itemValue={interestItem.name} itemClassName="cp-resume-interest-name" itemType={pipes} />
        <PipeItem
          itemValue={interestItem.keywords?.join(", ")}
          itemClassName="cp-resume-interest-keywords"
          itemType={pipes}
        />
      </Pipes>
    </li>
  );
};

export default InterestItem;
