import getBase64String from "@/utils/getBase64String";
import isString from "@/utils/base/isString";

type Base64StringProps = {
  toTransform?: string;
  base64Prefix?: string;
};

const transformBase64 = ({ toTransform, base64Prefix = "b64-" }: Base64StringProps) => {
  return isString(toTransform) && toTransform.startsWith(base64Prefix)
    ? getBase64String({ value: toTransform.replace(base64Prefix, "") })
    : toTransform;
};

export default transformBase64;
