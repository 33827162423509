import { DefaultToolbarProps } from "@/consts";
import { ToolbarConfigType } from "@/types/base";

type UseToolbarConfigProps = ToolbarConfigType & {
  defaultConfig?: Omit<ToolbarConfigType, "iconSet" | "resumeData">;
};

type UseToolbarConfigReturnProps = {
  toolbarProps: ToolbarConfigType;
};
const useToolbarConfig = ({
  defaultConfig = DefaultToolbarProps,
  hide = false,
  ...toolbarRestProps
}: UseToolbarConfigProps): UseToolbarConfigReturnProps => {
  const hideResumeSelect =
    toolbarRestProps.hideResumeSelect ||
    ((!hide || toolbarRestProps.hideDemoOptions) &&
      toolbarRestProps.hideResumeOption &&
      toolbarRestProps.hideFullResumeOption);
  const hideResumeOption = toolbarRestProps.hideResumeOption || hide;
  const hideFullResume = toolbarRestProps.hideFullResumeOption || hide;
  const hideDarkModeSelect = toolbarRestProps.hideDarkModeSelect || hide;
  const hideJson = toolbarRestProps.hideJson || hide;
  const hidePDF = toolbarRestProps.hidePDF || hide;
  const hidePrint = toolbarRestProps.hidePrint || hide;

  const toolbarProps: ToolbarConfigType = Object.assign({}, defaultConfig, toolbarRestProps, {
    hideResumeSelect,
    hideResumeOption,
    hideFullResume,
    hideDarkModeSelect,
    hideJson,
    hidePDF,
    hidePrint,
    hide,
  });

  return {
    toolbarProps,
  };
};

export default useToolbarConfig;
