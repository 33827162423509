import "@/components/resume/lists/publications/Publications.scss";
import { checkKeyViewable, createKeyFrom, isArray, isNil } from "@/utils";

import { JSX } from "react";
import PublicationItem from "@/components/resume/lists/publications/PublicationItem";
import { PublicationsType } from "@/types/resume";
import Section from "@/components/resume/sections/Section";
import { useResumeConfig } from "@/context/resume";

type PublicationsProps = {
  publications?: PublicationsType;
  header?: string;
};

const Publications = ({ publications = [], header = "Publications:" }: PublicationsProps): JSX.Element | null => {
  const { titles, viewable } = useResumeConfig();

  if (
    !checkKeyViewable("publications", viewable) ||
    !publications ||
    isNil(publications) ||
    (isArray(publications) && publications.length < 1)
  ) {
    return null;
  }
  const heading = titles?.publications ?? header;

  return (
    <Section className="cp-resume-publications" heading={heading}>
      <ul className="cp-resume-publications-list">
        {publications.map((publication) => {
          return publication.hide ? null : (
            <PublicationItem key={createKeyFrom(publication.name)} publicationItem={publication} />
          );
        })}
      </ul>
    </Section>
  );
};

export default Publications;
