import { ResumeViewableType } from "@/types/resume";

const DefaultViewableSet: ResumeViewableType[] = [
  "basics",
  "basics.name",
  "basics.label",
  "basics.url",
  "basics.summary",
  "basics.email",
  "basics.profiles",
  "skills",
  "work",
  "education",
];

export default DefaultViewableSet;
