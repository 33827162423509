import DefaultResumeThemes from "@/consts/DefaultResumeThemes";
import { ResumeThemeOptionItem } from "@/types/resume";

const DefaultResumeThemeOptions: ResumeThemeOptionItem[] = DefaultResumeThemes.map((theme) => {
  return {
    text: theme,
    value: theme,
  };
});

export default DefaultResumeThemeOptions;
