import { useEffect, useState } from "react";
import { ResumeThemeType } from "@/types/resume";
import includesSupportedTheme from "@/utils/includesSupportedTheme";
import isNil from "@/utils/base/isNil";

type useSideWheelScrollProps = {
  scrollEl?: HTMLElement | null;
  theme?: ResumeThemeType;
  scrollSegmentWidth?: number;
  wideThemeBreakpoint?: number;
};

type useSideWheelScrollReturnProps = {
  scrollEl?: HTMLElement | null;
};

const useSideWheelScroll = ({
  scrollEl,
  scrollSegmentWidth = 90,
  wideThemeBreakpoint = 1920,
  theme,
}: useSideWheelScrollProps): useSideWheelScrollReturnProps => {
  const [listening, setListening] = useState(false);
  const isSideScrolling = includesSupportedTheme({ theme, themeSearch: "side-scroll" });

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      const isScreenWide = window.innerWidth > wideThemeBreakpoint;
      if (isScreenWide && !isNil(scrollEl)) {
        const scrollToLeft = e.deltaY < 0 ? -scrollSegmentWidth : scrollSegmentWidth;
        const isAtBeginning = scrollEl.scrollLeft === 0;
        const isAtEnd = scrollEl.scrollLeft + scrollEl.clientWidth >= scrollEl.scrollWidth;
        const hasScrollbars = scrollEl.scrollWidth > scrollEl.clientWidth;

        if (hasScrollbars) {
          scrollEl?.classList.add("has-scrollbars");
        } else {
          scrollEl?.classList.remove("has-scrollbars");
        }

        // check if the user is at the beginning or has reached the end of the scroll slider
        if (isAtBeginning && e.deltaY < 0) {
          if (hasScrollbars) scrollEl?.classList.add("scroll-begin");
        } else {
          scrollEl?.classList.remove("scroll-begin");
        }

        if (isAtEnd && e.deltaY > 0) {
          if (hasScrollbars) scrollEl?.classList.add("scroll-end");
        } else {
          scrollEl?.classList.remove("scroll-end");
          scrollEl.scrollLeft += scrollToLeft;
        }

        scrollEl.scrollLeft += scrollToLeft;
      }
    };

    if (!isNil(scrollEl)) {
      scrollEl.removeEventListener("wheel", handleWheel);
      const hasScrollbars = scrollEl.scrollWidth > scrollEl.clientWidth;

      if (isSideScrolling && !listening && !!scrollEl.addEventListener) {
        setListening((currentValue) => currentValue);
        scrollEl.addEventListener("wheel", handleWheel, { passive: true });
      }

      if (isSideScrolling || listening) {
        scrollEl.classList.add("cp-resume-scroll-el");
        scrollEl.style.scrollSnapType = "none";

        if (hasScrollbars) {
          scrollEl?.classList.add("has-scrollbars");
          if (scrollEl.scrollLeft === 0) scrollEl.classList.add("scroll-begin");
        } else {
          scrollEl?.classList.remove("scroll-begin", "scroll-end", "has-scrollbars");
        }
      } else {
        scrollEl.style.transition = "none";
        scrollEl.style.scrollSnapType = "x proximity";
        scrollEl?.classList.remove("cp-resume-scroll-el", "scroll-begin", "scroll-end", "has-scrollbars");
      }
    }

    return () => {
      if (!isSideScrolling || !listening) scrollEl?.removeEventListener("wheel", handleWheel);
    };
  }, [isSideScrolling, listening, scrollEl, scrollSegmentWidth, wideThemeBreakpoint, theme]);

  return {
    scrollEl,
  };
};

export default useSideWheelScroll;
