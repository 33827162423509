import "@/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import ResumeApp from "@/components/ResumeApp";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ResumeApp />
  </React.StrictMode>
);
